<template>
  <div>
    <b-row>
      <b-col
        lg="6"
        md="6"
        class="mb-2"
      >
        <b-skeleton-img
          v-if="spinner"
          class="mb-4"
        />
        <CardEarningReports
          v-else
          :stats-data="statsData ? statsData.earningDetails : null"
          :date-sequence="dateSequence"
          :earning-sequence="earningSequence"
        />
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-row>
          <b-col
            lg="6"
            md="6"
            class="mb-1"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-horizontal
              v-else
              icon="DollarSignIcon"
              color="success"
              :statistic="`$ ${statsData ? (statsData.adminStats.simiTotalRevenue).toFixed(2): 0 }`"
              style="min-height: 165px;"
              statistic-title="Total Earnings SIMI"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-horizontal
              v-else
              icon="UserIcon"
              color="primary"
              :statistic="`${kFormatter(Math.round(Number(statsData.adminStats.businessCount)))}`"
              style="min-height: 165px;"
              statistic-title="Total Subscriptions"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            lg="6"
            md="6"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-horizontal
              v-else
              icon="DollarSignIcon"
              color="success"
              :statistic="`$ ${Number(statsData.adminStats.venueEarnings)}`"
              style="min-height: 170px;"
              statistic-title="Venue Owner Revenue Shared"
            />
          </b-col>
          <b-col
            lg="6"
            md="6"
          >
            <b-skeleton-img
              v-if="spinner"
            />
            <statistic-card-horizontal
              v-else
              icon="DollarSignIcon"
              color="success"
              :statistic="`$ ${Number(statsData.adminStats.agencyEarnings)}`"
              style="min-height: 170px;"
              statistic-title="Agency Revenue Shared"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-tabs
            pills
            nav-class="nav-pill-primary d-flex justify-content-center"
          >
            <b-tab
              title="Subscriptions"
              active
            >
              <CardAdminGetSubscriptionInvoices />
            </b-tab>
            <b-tab
              title="External Ads"
            >
              <CardAdminGetExternalInvoices />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-tabs
            pills
            nav-class="nav-pill-primary d-flex justify-content-center"
          >
            <b-tab
              title="Refunds"
              active
            >
              <CardAdminGetRefundList />
            </b-tab>
            <b-tab
              title="Venue Owner Revenue"
            >
              <CardAdminGetVenueOwnerRevenueList />
            </b-tab>
            <b-tab
              title="Agency Revenue"
            >
              <CardAdminGetAgencyRevenueList />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-tabs
            pills
            nav-class="nav-pill-primary d-flex justify-content-center"
          >
            <b-tab
              title="External Ad Earnings"
              active
            >
              <CardAdminGetExternalCompletionProfitTable />
            </b-tab>
            <b-tab
              title="External Ad Processing Fees"
            >
              <CardAdminGetExternalCreatingProfitTable />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
  BTabs,
  BTab,
  BSkeletonImg,
} from 'bootstrap-vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CardEarningReports from '@/components/common/CardEarningReports.vue'
import CardAdminGetExternalInvoices from '@/components/admin/CardAdminGetExternalInvoices.vue'
import CardAdminGetSubscriptionInvoices from '@/components/admin/CardAdminGetSubscriptionInvoices.vue'
import CardAdminGetRefundList from '@/components/admin/CardAdminGetRefundList.vue'
import CardAdminGetAgencyRevenueList from '@/components/admin/CardAdminGetAgencyRevenueList.vue'
import CardAdminGetVenueOwnerRevenueList from '@/components/admin/CardAdminGetVenueOwnerRevenueList.vue'
import CardAdminGetExternalCreatingProfitTable from '@/components/admin/CardAdminGetExternalCreatingProfitTable.vue'
import CardAdminGetExternalCompletionProfitTable from '@/components/admin/CardAdminGetExternalCompletionProfitTable.vue'
import { kFormatter } from '@/@core/utils/filter'
import { showToast } from '@/common/global/functions'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    BSkeletonImg,
    // BCardTitle,
    CardEarningReports,
    StatisticCardHorizontal,
    CardAdminGetExternalInvoices,
    CardAdminGetSubscriptionInvoices,
    CardAdminGetRefundList,
    CardAdminGetAgencyRevenueList,
    CardAdminGetVenueOwnerRevenueList,
    CardAdminGetExternalCreatingProfitTable,
    CardAdminGetExternalCompletionProfitTable,
  },
  data() {
    return {
      spinner: false,
      statsData: null,
    }
  },
  computed: {
    dateSequence() {
      return this.statsData?.earningDetails ? this.statsData.earningDetails.last7DaysEarningsArray.map(item => item.date) : []
    },
    earningSequence() {
      return this.statsData?.earningDetails ? this.statsData.earningDetails.last7DaysEarningsArray.map(item => item.earning) : []
    },
  },
  mounted() {
    this.getEarningStats()
  },
  methods: {
    kFormatter,
    async getEarningStats() {
      try {
        this.spinner = true
        const response = await this.$axios.get('/admin/pmp-ad/simi-earning-stats')
        this.statsData = response.data.data
      } catch (error) {
        console.error(error.messgae)
        showToast('Billing Details', error.messgae, 'danger')
      } finally {
        this.spinner = false
      }
    },
  },
}
</script>

  <style scoped>
  .card {
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.25) !important;
    border: 1px solid rgba(34, 41, 47, 0.125) !important;
  }
  </style>
