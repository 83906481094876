<template>
  <div>
    <b-card no-body>
      <b-skeleton-table
        v-if="spinner"
        :rows="paginationObject.perPage"
        :columns="8"
        :table-props="{
          bordered: true,
          striped: true,
          tableClass: 'skeleton-loading-table',
        }"
      />
      <b-table
        v-else
        id="allInvoices-table"
        hover
        selectable
        :select-mode="selectMode"
        :items="allInvoices"
        responsive
        :fields="tableColumns"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Group-name -->
        <template #cell(CAMPAIGN_NAME)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.pmp_ads.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Group-name -->
        <template #cell(BUSINESS_NAME)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.pmp_ads.user_has_group.name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Payment-type -->
        <template #cell(PAYMENT_TYPE)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.payment_for }}
            </span>
          </b-media>
        </template>

        <!-- Column: Payment-type -->
        <template #cell(NO_OF_ATTEMPTS)="data">
          <b-media
            vertical-align="center"
            class="text-nowrap pl-2"
          >
            <span class="text-nowrap">
              {{ data.item.attempts ? data.item.attempts : "---" }}
            </span>
          </b-media>
        </template>
        <!-- Column: Amount -->
        <template #cell(AMOUNT)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap"> $ {{ Number(data.item.refund_amount) }} </span>
          </b-media>
        </template>

        <!-- Column: Invoice Date -->
        <template #cell(USER_TYPE)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap">
              {{ data.item.pmp_ads.user_type }}
            </span>
          </b-media>
        </template>

        <template #cell(REFUND_ID)="data">
          <b-media vertical-align="center">
            <span class="text-nowrap">
              {{ data.item.refundId || '--' }}
            </span>
          </b-media>
        </template>
        <!-- Column: Status -->
        <template #cell(STATUS)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.payment_status
            )}`"
            class="text-capitalize"
          >
            {{ data.item.payment_status }}
          </b-badge>
        </template>
      </b-table>
    </b-card>
    <div class="mt-0">
      <div class="text-nowrap">
        <!-- pagination -->
        <CustomPagination
          :total="paginationObject.total"
          :per-page="paginationObject.limit"
          :page="paginationObject.offset"
          @perPageChanged="perPageChanged"
          @pageChanged="pageChanged"
        />
      </div>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BMedia,
  VBToggle,
  BBadge,
  BSkeletonTable,
  // BPagination,
  VBTooltip,
} from 'bootstrap-vue'
import Vue from 'vue'
import Ripple from 'vue-ripple-directive'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import moment from 'moment'
import { getDateFromDateTime, getTimeFromDateTime, showToast } from '@/common/global/functions'
import CustomPagination from '@/components/common/CustomPagination.vue'

Vue.prototype.moment = moment
Vue.use(Vuesax)
Vue.directive('ripple', Ripple)
export default {
  components: {
    CustomPagination,
    BCard,
    BTable,
    BMedia,
    BBadge,
    BSkeletonTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      spinner: false,
      allInvoices: [],
      tableColumns: [
        { key: 'BUSINESS_NAME', label: 'BUSINESS NAME' },
        { key: 'CAMPAIGN_NAME', label: 'CAMPAIGN NAME' },
        { key: 'AMOUNT' },
        { key: 'USER_TYPE', label: 'USER TYPE' },
        { key: 'REFUND_ID', label: 'REFUND ID' },
        { key: 'STATUS' },
      ],
      selectMode: 'single',
      paginationObject: {
        offset: 1,
        limit: 5,
        total: 0,
      },
    }
  },
  async mounted() {
    await this.getAllInvoicesOfUser(this.paginationObject)
  },
  methods: {
    getDateFromDateTime,
    getTimeFromDateTime,
    perPageChanged(limit) {
      this.paginationObject.offset = 1
      this.paginationObject.limit = limit
      this.getAllInvoicesOfUser(this.paginationObject)
    },
    pageChanged(offset) {
      this.paginationObject.offset = offset
      this.getAllInvoicesOfUser(this.paginationObject)
    },
    async getAllInvoicesOfUser({ offset, limit }) {
      this.paginationObject = { ...this.paginationObject, offset, limit }
      this.spinner = true
      try {
        const response = await this.$axios.get(
          `/admin/pmp-ad/refund-venue-revenue-table?table_type=refunds&offset=${offset - 1}&limit=${limit}`, {
          },
        )
        this.allInvoices = response.data.data.records
        this.paginationObject.total = response.data.data.count
        this.spinner = true
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        showToast('Billing Details', message.toString(), 'danger')
      }
      this.spinner = false
    },
    resolveUserStatusVariant(Status) {
      if (Status === 'requested') return 'secondary'
      if (Status === 'paid') return 'success'
      if (Status === 'pending') return 'warning'
      if (Status === 'failed') return 'danger'
      return ''
    },
  },
}
</script>
